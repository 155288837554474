export default {
    red: "#e5243b",
    mustard: "#dda63a",
    kellyGreen: "#4c9f38",
    darkRed: "#c5192d",
    redOrange: "#ff3a21",
    brightBlue: "#26bde2",
    yellow: "#fcc30b",
    burgundyRed: "#a21942",
    orange: "#fd6925",
    magenta: "#dd1367",
    goldenYellow: "#df9d24",
    darkMustard: "#bf8b2e",
    darkGreen: "#3f7e44",
    blue: "#0a97d9",
    limeGreen: "#56c02b",
    royalBlue: "#00689d",
    navyBlue: "#19486a",
    gray: "#f1f1f1",
    white: "#ffffff",
    black: "#000000",
};
