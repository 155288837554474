import styled from "styled-components";

export const PageContainer = styled.div`
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    padding: 10px;
`;

export const Title = styled.div``;
