/*
 * the function returns a normalization of the structure generated by distribute function
 * the goal is that every goal in a column has at leat one square, and that the sum of the squares
 * in a column does not excede 17
 */
export default (rawSDGImpactMap) => {
    // if the sum of the values for every column is not greater than 17 a normalization is not require
    if (
        !rawSDGImpactMap
            .map((column) => Object.values(column).reduce((acc, value) => acc + value, 0) > 17)
            .find((sumIsGreaterThan17) => sumIsGreaterThan17)
    ) {
        return rawSDGImpactMap;
    }

    // get min value
    const min = rawSDGImpactMap.reduce((currentMin, i) => {
        const values = Object.values(i);
        return Math.min(...values) < currentMin ? Math.min(...values) : currentMin;
    }, 17);

    // normalize the series, after that, the elements with value equal to min are removed
    // because for those 1 is setted by default
    return rawSDGImpactMap.map((column) => {
        // count the goals that has one square
        const oneSquareGoalsCount = Object.values(column).reduce((acc, value) => (value === min ? ++acc : acc), 0);
        const columnValuesSum = Object.values(column).reduce((acc, value) => acc + value, 0);
        return Object.keys(column).reduce(
            (acc, key) =>
                column[key] === min
                    ? { ...acc, [key]: 1 }
                    : {
                          ...acc,
                          [key]: Math.round(((17 - oneSquareGoalsCount) * column[key]) / columnValuesSum),
                      },
            {}
        );
    });
};
